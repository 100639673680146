import { FC, useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import frontImage from "../../../images/question-mark-3245622_1280.jpg"
import { Button, CircularProgress } from '@mui/material';


const LoginComponent: FC = () => {
    const auth = useAuth();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    let createUserBaseUrl: string | undefined = process.env.REACT_APP_CreateUserBaseUrl;

    useEffect(() => {
    }, [auth, navigate])

    function onRegisterClicked() {
        const relativePath = '/account/registered';
        const returnUrl = new URL(relativePath, window.location.origin).href;
        let urlToCreateAccount = `${createUserBaseUrl}?ReturnUrl=${encodeURI(returnUrl)}`;
        window.location.href = urlToCreateAccount;
    }

    const handleLoginClick = async () => {
        console.log("Environment variables:");
        Object.keys(process.env)
            .filter(key => key.startsWith("REACT_APP_"))
            .forEach(key => {
                console.log(`${key}: ${process.env[key]}`)
            })

        setIsLoading(true);
        
        try {
            await auth.signinRedirect();
        } catch (error) {
            console.error('Login failed', error);
        } finally {
            setIsLoading(false);
        }
    };

    const LoginForm = () => {
        if (!auth.isAuthenticated) {
            return <>
                <div id='loginContainer'>
                    <div className='quizTitleContainer'>
                        <h1>QuizLab Béta</h1> 
                        <p>Everyone loves sport. And everyone loves a quiz.</p>
                    </div>
                </div>

                <div id='loginAuthenticationContainer'>

                    <div id='loginAuthenticationImageContainer'>
                        <img src={frontImage}></img>
                    </div>
                    <div id='loginAuthenticationButtons'>
                        <Button variant="contained" onClick={onRegisterClicked}>Register</Button>
                        <Button variant="contained" onClick={handleLoginClick} disabled={isLoading}>
                            {isLoading ? <CircularProgress size={24} /> : 'Login'}
                        </Button>
                    </div>

                </div>
            </>

        } else {
            navigate('/quiz');
        }
    }

    return (
       <LoginForm></LoginForm>
    )
};

export default LoginComponent;
